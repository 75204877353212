<template>
  <div>
    <v-row>
      <v-col>
        <span>Step 2: Select a location by clicking on it below:</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="locations.length > 0">
        <v-card :class="selectedLocation === item ? 'active ma-4' : 'ma-4' " v-for="(item, index) in locations" :key="index" @click="selectLocation(item)">
          <v-card-title>
            {{ item.locationName }}
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <span v-html="getLocationAddressString(item)"></span>
              </v-col>
              <v-col cols="12" sm="12" md="4">
                <v-chip
                  :color="getDistanceColor(item.distanceFromZip)"
                  dark
                  small
                  class="ma-2"
                >
                  <span class="subtitle-2">about {{ Math.round(item.distanceFromZip) }} miles</span>
                </v-chip>
                <manufacturer-chip :manufacturerId="item.vaccineManufacturerId" :manufacturerName="item.vaccineManufacturerName"  v-if="item.vaccineManufacturerId > 0"></manufacturer-chip>
                <dose-type-chip :doseType="item.vaccineDoseType" v-if="item.vaccineDoseType"></dose-type-chip>
              </v-col>
              <v-col cols="12" sm="12" md="2">
                <a :href="'tel:' + item.phone">{{ item.phone }}</a>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col v-else-if="!loadingLocations">
         <span class="error--text">Please contact your facilities employee health team to find a location near you for your next shot.</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
          <v-btn x-large color="primary" style="width: 100%;" v-if="!noMoreLocationsFlag" @click="getMoreLocations" :loading="loadingLocations" :disabled="loadingLocations">Show More Locations</v-btn>
          <v-btn x-large style="width: 100%;" v-if="noMoreLocationsFlag" disabled>No More Locations Available</v-btn>
      </v-col>
      <v-col cols="12">
          <v-btn x-large style="width: 100%;" @click="goBack">Go Back</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
  props: {
    zipCode: {
      type: String,
      default: null,
      required: true
    },
    currentDoseType: {
      type: String,
      default: null,
      required: true
    },
    vaccineManufacturerId: {
      type: Number,
      default: 0,
      required: true
    },
    user: {
      type: Object,
      default: () => {},
      required: true
    }
  },
  components: {
    manufacturerChip: () => import('@/module/vaccineManufacturer/components/manufacturerChip'),
    doseTypeChip: () => import('@/module/vaccineManufacturer/components/doseTypeChip'),
    datePickerWidget: () => import('@/module/vaccineScheduling/signup/components/datePickerWidget')
  },
  watch: {
  },
  computed: {
  },
  data() {
    return {
      numLocationResults: 5,
      locationPage: 0,
      locations: [ ],
      noMoreLocationsFlag: false,
      loadingLocations: false,
      selectedLocation: null,
      required: [
        v => !!v || 'Required Field'
      ]
    }
  },
  methods: {
    ...mapActions('vaccineLocations', ['getLocationsByZip']),
    getDistanceColor: function (distance) {
      if (distance < 50) {
        return 'green'
      } else if (distance < 100) {
        return 'warning'
      } else {
        return 'error'
      }
    },
    getLocationAddressString: function (item) {
      return item.address + '<br/>' + (item.address2 ? item.address2 + '<br/>' : '') + item.city + ', ' + item.state + ' ' + item.zip
    },
    getMoreLocations: function () {
      this.locationPage++
      this.getLocations()
    },
    getLocations: function () {
      this.loadingLocations = true
      return this.getLocationsByZip({ zipCode: this.zipCode, numResults: this.numLocationResults, pageIndex: this.locationPage, doseType: this.currentDoseType, user34: this.user.user34, milesLimit: 50, vaccineManufacturerId: this.vaccineManufacturerId }).then((response) => {
        this.loadingLocations = false
        if (response === undefined) {
          this.zipcodeError = ['This zip code is invalid. Please check it and try again']
          return false
        }
        if (response.data.length === 0) {
          this.noMoreLocationsFlag = true
        } else {
          this.locations.push(...response.data)
        }
      })
    },
    selectLocation: function (item) {
      this.selectedLocation = item
      this.$emit('locationSelected', this.selectedLocation)
    },
    initialLocationLoad: function () {
      this.noMoreLocationsFlag = false
      this.locationPage = 0
      this.locations = [ ]
      this.getLocations().then(() => {
        if (this.locations.length > 0) {
        }
      })
    },
    goBack: function () {
      this.$emit('goBack')
    }
  },
  mounted() {
    this.initialLocationLoad()
  },
};
</script>
